/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://l7wtwbchvffnxlzlblx4kvq4wq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bemcd5eptbgcromtuldslgi3gq",
    "aws_cognito_identity_pool_id": "us-east-1:eea055de-f40e-4d59-b87a-a5d365e93882",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tI7ipFvLA",
    "aws_user_pools_web_client_id": "7r7j42mocvv1obag3e3l0tdjvl",
    "oauth": {}
};


export default awsmobile;
